.rgcn {
  color: white;
  min-height: 650px;
}

.rgcn h1 {
  font-size: 1.7rem;
  margin: 50px 0 0 0;
  text-align: center;
  font-weight: 600;
}
.rgcn h4 {
  font-size: 1.3rem;
  text-align: center;
  color: white;
  padding: 0;
  margin-top: 5px;
}

.rgcn .github {
  text-align: center;
  margin: 5px 0 35px 0;
}

.rgcn .content {
  padding: 0 auto;
}

.rgcn .content p {
  margin-bottom: 40px;
}

.rgcn .content ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.rgcn a:link {
  color: rgb(206, 203, 249);
}

/* visited link */
.rgcn a:visited {
  color: rgb(79, 158, 184);
}

/* mouse over link, selected link */
.rgcn a:hover,
.rgcn a:active {
  color: rgba(155, 138, 240, 0.879);
}
