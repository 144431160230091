.gcnwithkd {
  color: white;
  min-height: 650px;
}

.gcnwithkd h1 {
  font-size: 1.7rem;
  margin: 50px 0 0 0;
  text-align: center;
  font-weight: 600;
}
.gcnwithkd h4 {
  font-size: 1.3rem;
  text-align: center;
  color: white;
  padding: 0;
  margin-top: 5px;
}

.gcnwithkd ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 80px;
}

.gcnwithkd .github {
  text-align: center;
  margin: 5px 0 35px 0;
}

.gcnwithkd .content {
  padding: 0 auto;
}

.gcnwithkd .content p {
  margin-bottom: 40px;
}

.gcnwithkd a:link {
  color: rgb(206, 203, 249);
}

/* visited link */
.gcnwithkd a:visited {
  color: rgb(79, 158, 184);
}

/* mouse over link, selected link */
.gcnwithkd a:hover,
.gcnwithkd a:active {
  color: rgba(155, 138, 240, 0.879);
}
