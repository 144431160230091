/* Homepage */
.intro_h1 {
  font-size: 80px;
  font-weight: 500;
  color: white;
}
.intro_h3 {
  color: white;
  font-size: 1.5rem;
  font-weight: 500;
}

.intro_hr {
  margin: 15px auto;
  border: 1px solid #676666;
}

.intro_btn {
  margin-top: 10px;
  opacity: 0.8;
  font-weight: 550;
  background-color: rgb(165, 58, 140);
  border: none;
}

.intro_btn:visited,
.intro_btn:focus,
.intro_btn:hover,
.intro_btn:active {
  opacity: 0.8;
  font-weight: 550;
  background-color: rgb(179, 107, 162);
}

.cardimgs {
  align-content: 'center';
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.1);
}

.cardimg {
  clip-path: circle(50% at 50% 50%);
  width: 100%;
}

.cardimgWrap {
  margin: 10px auto 10px;
  padding: 10px;
  text-align: center;
}

.card {
  color: white;
  text-align: left;
  box-shadow: none;
  border: none;
  font-family: 'Noto Sans', sans-serif;
  padding: 5%;
}

.cardtext {
  margin-top: 40px;
}

.cardtext p {
  text-align: justify;
  text-justify: auto;
}

.cardtext a {
  color: rgb(177, 215, 241);
}

@media (min-width: 801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .cardimg {
    margin: 30px 0;
    padding: 0;
    clip-path: circle(50% at 50% 50%);
    width: 75%;
  }
  .cardimgWrap {
    margin: 0;
    padding: 0;
  }
}
