.PostMedia {
  margin: 25px;
  padding: 20px;
  color: rgb(39, 1, 46);
  background-color: rgba(193, 193, 193, 0.3);
  border-radius: 15px;
}

.PostMedia img {
  max-width: 100%;
  transition: all 0.5s ease 0s;
}

.PostMedia img:hover {
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
  opacity: .5;
}

.PostMedia .postTitle {
  font-size: 1.5rem;
}

.PostMedia .postCreated {
  color: rgb(88, 5, 104);
}
