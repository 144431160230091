/* Footer */

.Footer {
  color: white;
  background-color: rgba(61, 63, 63, 0.3);
  margin: 0px auto;
  padding: 20px 0px 20px 0px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

.Footer address {
  font-size: 80%;
  margin: 0px;
}

.Footer .dropdownItem:hover,
.Footer .dropdownItem.active,
.Footer .dropdownItem:active {
  background: #c951c9;
  color: #343333;
  width: 100%;
}
