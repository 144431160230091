body {
  font-family: Helvetica, Arial, sans-serif;
}

.skills {
  color: rgb(255, 255, 255);
}

.skills h5 {
  font-weight: 700;
}

.bar-container {
  width: 50%;
  margin: 0 auto;
}
@keyframes load {
  from {
    width: 0%;
  }
}
@-webkit-keyframes load {
  from {
    width: 0%;
  }
}
@-moz-keyframes load {
  from {
    width: 0%;
  }
}
@-o-keyframes load {
  from {
    width: 0%;
  }
}

.bar {
  background-color: #eee;
  padding: 2px;
  border-radius: 15px;
  margin-bottom: 5px;
  font-size: 14px;
  color: #fff;
  font-weight: bold;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}
.bar::before {
  content: attr(data-skill);
  background-color: #aa53b9;
  display: inline-block;
  padding: 5px 0 5px 10px;
  border-radius: inherit;
  animation: load 2s 0s;
  -webkit-animation: load 2s 0s;
  -moz-animation: load 2s 0s;
  -o-animation: load 2s 0s;
}

.bar.purpleOne::before {
  background-color: #572368;
}
.bar.purpleTwo::before {
  background-color: #5a3068;
}
.bar.purpleThree::before {
  background-color: #912d89;
}
.bar.purpleFour::before {
  background-color: #b838ad;
}
.bar.purpleFive::before {
  background-color: #d0b4dc;
}
.bar.learning::before {
  width: calc(20% - 10px);
}
.bar.basic::before {
  background-color: #ce0ac7;
  width: calc(40% - 10px);
}
.bar.intermediate::before {
  width: calc(60% - 10px);
}
.bar.advanced::before {
  width: calc(80% - 10px);
}
.bar.expert::before {
  width: calc(100% - 10px);
}
