.Post {
  color: rgb(19, 18, 18);
  text-align: center;
  background-color: white;
}
.Post .image {
  display: block;
  margin: 35px auto;
  padding: 40px 0 0 0;
  filter: drop-shadow(0 0 1.2rem rgb(44, 46, 113));
  width: auto;
  max-height: 300px;
}

.Post h1 {
  padding-top: 15px;
  font-size: 2em;
  font-weight: 600;
}

.Post h5 {
  font-size: 1em;
  margin-top: 20px;
  margin-bottom: 16px;
  line-height: 1.25;
}

.Post .title {
  display: block;
  padding: 0px 15px;
}

.Post .created {
  font-size: 0.775em;
  text-align: right;
  margin: 0;
  padding: 0;
  color: grey;
}

.Post .buttons {
  text-align: right;
  padding: 20px 0;
  border-top: 1px solid rgb(62, 60, 60);
}
