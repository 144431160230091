.ProjectChild {
  color: white;
}

.ProjectChild img {
  display: block;
  width: 208px;
  height: 117px;
  margin: 20px;
  border: 3px solid white;
  border-radius: 10px;
}

.ProjectChild .content {
  margin: 20px auto 40px;
  border-bottom: 1px solid #ccc;
}

.ProjectChild p {
  font-size: 1rem;
  padding: 5px 0;
}

.ProjectChild .content .link {
  margin: 0 5px;
}
