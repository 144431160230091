@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Noto+Sans:wght@400;700&family=Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Google Fonts */

/* General */
.content {
  min-height: 910px;
  height: 100%;
}

.bg-img {
  background: #1d2b64; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top,
    #f8cdda,
    #1d2b64
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

a {
  color: #ddeff7;
}

/* Aboutpage */
.cardimg {
  margin: 20px auto 0 auto;
  max-width: 50%;
  -webkit-clip-path: circle(50% at 50% 50%);
          clip-path: circle(50% at 50% 50%);
}

.cardimgWrap {
  -webkit-filter: drop-shadow(0 0 0.5rem rgb(44, 46, 113));
          filter: drop-shadow(0 0 0.5rem rgb(44, 46, 113));
}
.card {
  color: #343333;
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  font-family: 'Noto Sans', sans-serif;
  padding: 5%;
}

.cardtext {
  font-size: 1.1rem;
  font-weight: 100;
  text-align: center;
}

/* Header */
.HeaderComponent_Navbar__31vSm {
  background-color: rgba(61, 63, 63, 0.3);
  padding: 20px;
}

.HeaderComponent_Navbar__31vSm .HeaderComponent_NavbarBrand__71mxL {
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: 2rem;
  font-style: italic;
  font-weight: 600;
}

.HeaderComponent_Navbar__31vSm #HeaderComponent_NavLink__2HuVl {
  font-family: 'Montserrat', sans-serif;
  color: white;
  font-weight: 700;
  padding: 1.1rem;
}
.HeaderComponent_Navbar__31vSm #HeaderComponent_NavLink__2HuVl:hover,
.HeaderComponent_Navbar__31vSm #HeaderComponent_NavLink__2HuVl.HeaderComponent_active__2jx6I,
.HeaderComponent_Navbar__31vSm #HeaderComponent_NavLink__2HuVl:active {
  font-family: 'Montserrat', sans-serif;
  background-color: rgba(226, 244, 244, 0.2);
  color: white;
  padding: 1.1rem;
}

.HeaderComponent_Navbar__31vSm .HeaderComponent_dropdownItem__1z7lk:hover,
.HeaderComponent_Navbar__31vSm .HeaderComponent_dropdownItem__1z7lk.HeaderComponent_active__2jx6I,
.HeaderComponent_Navbar__31vSm .HeaderComponent_dropdownItem__1z7lk:active {
  background: #c951c9;
  color: #343333;
  width: 100%;
}

/* Footer */

.FooterComponent_Footer__S3O1o {
  color: white;
  background-color: rgba(61, 63, 63, 0.3);
  margin: 0px auto;
  padding: 20px 0px 20px 0px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

.FooterComponent_Footer__S3O1o address {
  font-size: 80%;
  margin: 0px;
}

.FooterComponent_Footer__S3O1o .FooterComponent_dropdownItem__3UJ-i:hover,
.FooterComponent_Footer__S3O1o .FooterComponent_dropdownItem__3UJ-i.FooterComponent_active__1BUCm,
.FooterComponent_Footer__S3O1o .FooterComponent_dropdownItem__3UJ-i:active {
  background: #c951c9;
  color: #343333;
  width: 100%;
}

.Spinner_Loader__2hakv,
.Spinner_Loader__2hakv:after {
  border-radius: 50%;
  width: 5em;
  height: 5em;
}
.Spinner_Loader__2hakv {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ccc;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: Spinner_load8__1FLaS 1.1s infinite linear;
  animation: Spinner_load8__1FLaS 1.1s infinite linear;
}
@-webkit-keyframes Spinner_load8__1FLaS {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes Spinner_load8__1FLaS {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* Homepage */
.IntroComponent_intro_h1__1pCk2 {
  font-size: 80px;
  font-weight: 500;
  color: white;
}
.IntroComponent_intro_h3__1ciAC {
  color: white;
  font-size: 1.5rem;
  font-weight: 500;
}

.IntroComponent_intro_hr__3uXry {
  margin: 15px auto;
  border: 1px solid #676666;
}

.IntroComponent_intro_btn__3NRjo {
  margin-top: 10px;
  opacity: 0.8;
  font-weight: 550;
  background-color: rgb(165, 58, 140);
  border: none;
}

.IntroComponent_intro_btn__3NRjo:visited,
.IntroComponent_intro_btn__3NRjo:focus,
.IntroComponent_intro_btn__3NRjo:hover,
.IntroComponent_intro_btn__3NRjo:active {
  opacity: 0.8;
  font-weight: 550;
  background-color: rgb(179, 107, 162);
}

.IntroComponent_cardimgs__2RVwW {
  align-content: 'center';
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.1);
}

.IntroComponent_cardimg__1aOEb {
  -webkit-clip-path: circle(50% at 50% 50%);
          clip-path: circle(50% at 50% 50%);
  width: 100%;
}

.IntroComponent_cardimgWrap__3xAI3 {
  margin: 10px auto 10px;
  padding: 10px;
  text-align: center;
}

.IntroComponent_card__5BJNs {
  color: white;
  text-align: left;
  box-shadow: none;
  border: none;
  font-family: 'Noto Sans', sans-serif;
  padding: 5%;
}

.IntroComponent_cardtext__2AJPv {
  margin-top: 40px;
}

.IntroComponent_cardtext__2AJPv p {
  text-align: justify;
  text-justify: auto;
}

.IntroComponent_cardtext__2AJPv a {
  color: rgb(177, 215, 241);
}

@media (min-width: 801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .IntroComponent_cardimg__1aOEb {
    margin: 30px 0;
    padding: 0;
    -webkit-clip-path: circle(50% at 50% 50%);
            clip-path: circle(50% at 50% 50%);
    width: 75%;
  }
  .IntroComponent_cardimgWrap__3xAI3 {
    margin: 0;
    padding: 0;
  }
}

.HomeComponent_homepage__1U9cM {
  padding-top: 3%;
  padding-bottom: 3%;
}

.AboutComponent_aboutContainer__3C2bR {
  margin-bottom: 2.5rem;
  color: #343333;
}
.AboutComponent_aboutContainer__3C2bR hr {
  border-top: 1px solid rgb(155, 148, 148);
}

.AboutComponent_aboutContainer__3C2bR h1 {
  color: white;
  margin: 70px 0 30px 0;
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;
  border-bottom: 1px solid rgb(247, 247, 247);
}

.WorkComponent_card__3qoPa {
  text-align: left;
  padding: 10px;
  margin: 10px 0;
  border-radius: 20px;
}
.WorkComponent_cardTitle__3csEa {
  color: rgb(254, 243, 253);
  font-weight: 600;
  font-size: 2rem;
  padding: 0;
  margin: 0;
  display: block;
}

.WorkComponent_cardTitle__3csEa .WorkComponent_location__f64Vt {
  font-size: 1.2rem;
}

.WorkComponent_cardSubTitle__30RhC {
  display: inline;
  font-style: italic;
  font-weight: 600;
  font-size: 1.4rem;
  padding-left: 15px;
  color: rgb(247, 109, 109);
}

.WorkComponent_cardSubTitle__30RhC .WorkComponent_period__306TR {
  float: right;
  font-size: 1.1rem;
  font-style: initial;
  padding: 0;
  margin-left: 5px;
  color: rgb(254, 243, 253);
}
.WorkComponent_cardText__1GUfs {
  margin: 30px 0;
  padding: 15px;
  font-size: 1.1rem;
  color: rgb(249, 223, 246);
}
.WorkComponent_cardText__1GUfs li {
  padding: 5px;
}

body {
  font-family: Helvetica, Arial, sans-serif;
}

.skills {
  color: rgb(255, 255, 255);
}

.skills h5 {
  font-weight: 700;
}

.bar-container {
  width: 50%;
  margin: 0 auto;
}
@keyframes load {
  from {
    width: 0%;
  }
}
@-webkit-keyframes load {
  from {
    width: 0%;
  }
}

.bar {
  background-color: #eee;
  padding: 2px;
  border-radius: 15px;
  margin-bottom: 5px;
  font-size: 14px;
  color: #fff;
  font-weight: bold;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}
.bar::before {
  content: attr(data-skill);
  background-color: #aa53b9;
  display: inline-block;
  padding: 5px 0 5px 10px;
  border-radius: inherit;
  animation: load 2s 0s;
  -webkit-animation: load 2s 0s;
  -moz-animation: load 2s 0s;
  -o-animation: load 2s 0s;
}

.bar.purpleOne::before {
  background-color: #572368;
}
.bar.purpleTwo::before {
  background-color: #5a3068;
}
.bar.purpleThree::before {
  background-color: #912d89;
}
.bar.purpleFour::before {
  background-color: #b838ad;
}
.bar.purpleFive::before {
  background-color: #d0b4dc;
}
.bar.learning::before {
  width: calc(20% - 10px);
}
.bar.basic::before {
  background-color: #ce0ac7;
  width: calc(40% - 10px);
}
.bar.intermediate::before {
  width: calc(60% - 10px);
}
.bar.advanced::before {
  width: calc(80% - 10px);
}
.bar.expert::before {
  width: calc(100% - 10px);
}

.Extracurricular_card__1wTcf {
  text-align: left;
  padding: 10px;
  border-radius: 20px;
  margin-bottom: 10px;
}
.Extracurricular_cardTitle__3Wn_A {
  color: rgb(92, 46, 82);
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  font-size: 2rem;
  padding: 0;
  margin: 0;
}

.Extracurricular_cardSubTitle__1FTHT {
  color: rgb(138, 18, 110);
  display: flex;
  justify-content: space-between;
  font-style: italic;
  font-weight: 600;
  font-size: 1.2em;
  margin-top: 15px;
}

.Extracurricular_cardSubTitle__1FTHT .Extracurricular_period__2sAcN {
  font-size: 1.1rem;
  font-style: initial;
  padding: 0;
  margin-left: 5px;
}
.Extracurricular_cardText__3KsGb {
  margin-top: 15px;
  font-size: 1.1rem;
  color: rgb(65, 1, 49);
  padding: 15px;
}

.Extracurricular_cardText__3KsGb li {
  padding: 5px;
}

.SchoolComponent_school__PfcJG {
  color: rgb(79, 25, 65);
  font-size: 1.2em;
}

.SchoolComponent_school__PfcJG .SchoolComponent_SchoolName__3lkIn {
  font-weight: 600;
  color: rgb(72, 31, 63);
}

.SchoolComponent_school__PfcJG ul {
  padding-left: 20px;
}

.SchoolComponent_school__PfcJG ul li {
  padding-top: 10px;
}

.PostMedia_PostMedia__27bMx {
  margin: 25px;
  padding: 20px;
  color: rgb(39, 1, 46);
  background-color: rgba(193, 193, 193, 0.3);
  border-radius: 15px;
}

.PostMedia_PostMedia__27bMx img {
  max-width: 100%;
  transition: all 0.5s ease 0s;
}

.PostMedia_PostMedia__27bMx img:hover {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
  opacity: .5;
}

.PostMedia_PostMedia__27bMx .PostMedia_postTitle__3sYk9 {
  font-size: 1.5rem;
}

.PostMedia_PostMedia__27bMx .PostMedia_postCreated__Ig6OH {
  color: rgb(88, 5, 104);
}

.BlogComponent_Blog__1NqEa {
  color: black;
}

.BlogComponent_Blog__1NqEa .BlogComponent_title__2Craw {
  color: white;
  margin: 0;
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;
  border-bottom: 1px solid rgb(247, 247, 247);
}
.BlogComponent_Blog__1NqEa .BlogComponent_posts__862f8 {
  padding: 10px;
  margin: 40px auto;
  background-color: whitesmoke;
  border-radius: 15px;
}

.NewPost_newPost__w1ObY {
  color: white;
}
.NewPost_newPost__w1ObY .NewPost_title__35lCj {
  color: white;
  margin: 0;
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;
  border-bottom: 1px solid rgb(247, 247, 247);
}

.NewPost_newPost__w1ObY .NewPost_formLabel__1spsk {
  font-size: 1.3rem;
}

.PostComponent_Post__36FH4 {
  color: rgb(19, 18, 18);
  text-align: center;
  background-color: white;
}
.PostComponent_Post__36FH4 .PostComponent_image__2iDNZ {
  display: block;
  margin: 35px auto;
  padding: 40px 0 0 0;
  -webkit-filter: drop-shadow(0 0 1.2rem rgb(44, 46, 113));
          filter: drop-shadow(0 0 1.2rem rgb(44, 46, 113));
  width: auto;
  max-height: 300px;
}

.PostComponent_Post__36FH4 h1 {
  padding-top: 15px;
  font-size: 2em;
  font-weight: 600;
}

.PostComponent_Post__36FH4 h5 {
  font-size: 1em;
  margin-top: 20px;
  margin-bottom: 16px;
  line-height: 1.25;
}

.PostComponent_Post__36FH4 .PostComponent_title__30nnF {
  display: block;
  padding: 0px 15px;
}

.PostComponent_Post__36FH4 .PostComponent_created__3Cckw {
  font-size: 0.775em;
  text-align: right;
  margin: 0;
  padding: 0;
  color: grey;
}

.PostComponent_Post__36FH4 .PostComponent_buttons__3N4dx {
  text-align: right;
  padding: 20px 0;
  border-top: 1px solid rgb(62, 60, 60);
}

.markdown-body {
  text-align: left;
  padding: 0 5vw 5vw 5vw;
  margin-bottom: 40px;
}

.markdown-body .octicon {
  display: inline-block;
  fill: currentColor;
  vertical-align: text-bottom;
}

.markdown-body .anchor {
  float: left;
  line-height: 1;
  margin-left: -20px;
  padding-right: 4px;
}

.markdown-body .anchor:focus {
  outline: none;
}

.markdown-body h1 .octicon-link,
.markdown-body h2 .octicon-link,
.markdown-body h3 .octicon-link,
.markdown-body h4 .octicon-link,
.markdown-body h5 .octicon-link,
.markdown-body h6 .octicon-link {
  color: #1b1f23;
  vertical-align: middle;
  visibility: hidden;
}

.markdown-body h1:hover .anchor,
.markdown-body h2:hover .anchor,
.markdown-body h3:hover .anchor,
.markdown-body h4:hover .anchor,
.markdown-body h5:hover .anchor,
.markdown-body h6:hover .anchor {
  text-decoration: none;
}

.markdown-body h1:hover .anchor .octicon-link,
.markdown-body h2:hover .anchor .octicon-link,
.markdown-body h3:hover .anchor .octicon-link,
.markdown-body h4:hover .anchor .octicon-link,
.markdown-body h5:hover .anchor .octicon-link,
.markdown-body h6:hover .anchor .octicon-link {
  visibility: visible;
}

.markdown-body h1:hover .anchor .octicon-link:before,
.markdown-body h2:hover .anchor .octicon-link:before,
.markdown-body h3:hover .anchor .octicon-link:before,
.markdown-body h4:hover .anchor .octicon-link:before,
.markdown-body h5:hover .anchor .octicon-link:before,
.markdown-body h6:hover .anchor .octicon-link:before {
  width: 16px;
  height: 16px;
  content: ' ';
  display: inline-block;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' version='1.1' width='16' height='16' aria-hidden='true'%3E%3Cpath fill-rule='evenodd' d='M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z'%3E%3C/path%3E%3C/svg%3E");
}.markdown-body {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  line-height: 1.5;
  color: #24292e;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;
  font-size: 16px;
  line-height: 1.5;
  word-wrap: break-word;
}

.markdown-body details {
  display: block;
}

.markdown-body summary {
  display: list-item;
}

.markdown-body a {
  background-color: initial;
}

.markdown-body a:active,
.markdown-body a:hover {
  outline-width: 0;
}

.markdown-body strong {
  font-weight: inherit;
  font-weight: bolder;
}

.markdown-body h1 {
  font-size: 2em;
  margin: .67em 0;
}

.markdown-body img {
  border-style: none;
}

.markdown-body code,
.markdown-body kbd,
.markdown-body pre {
  font-family: monospace,monospace;
  font-size: 1em;
}

.markdown-body hr {
  box-sizing: initial;
  height: 0;
  overflow: visible;
}

.markdown-body input {
  font: inherit;
  margin: 0;
}

.markdown-body input {
  overflow: visible;
}

.markdown-body [type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

.markdown-body * {
  box-sizing: border-box;
}

.markdown-body input {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.markdown-body a {
  color: #0366d6;
  text-decoration: none;
}

.markdown-body a:hover {
  text-decoration: underline;
}

.markdown-body strong {
  font-weight: 600;
}

.markdown-body hr {
  height: 0;
  margin: 15px 0;
  overflow: hidden;
  background: transparent;
  border: 0;
  border-bottom: 1px solid #dfe2e5;
}

.markdown-body hr:after,
.markdown-body hr:before {
  display: table;
  content: "";
}

.markdown-body hr:after {
  clear: both;
}

.markdown-body table {
  border-spacing: 0;
  border-collapse: collapse;
}

.markdown-body td,
.markdown-body th {
  padding: 0;
}

.markdown-body details summary {
  cursor: pointer;
}

.markdown-body kbd {
  display: inline-block;
  padding: 3px 5px;
  font: 11px SFMono-Regular,Consolas,Liberation Mono,Menlo,monospace;
  line-height: 10px;
  color: #444d56;
  vertical-align: middle;
  background-color: #fafbfc;
  border: 1px solid #d1d5da;
  border-radius: 3px;
  box-shadow: inset 0 -1px 0 #d1d5da;
}

.markdown-body h1,
.markdown-body h2,
.markdown-body h3,
.markdown-body h4,
.markdown-body h5,
.markdown-body h6 {
  margin-top: 0;
  margin-bottom: 0;
}

.markdown-body h1 {
  font-size: 32px;
}

.markdown-body h1,
.markdown-body h2 {
  font-weight: 600;
}

.markdown-body h2 {
  font-size: 24px;
}

.markdown-body h3 {
  font-size: 20px;
}

.markdown-body h3,
.markdown-body h4 {
  font-weight: 600;
}

.markdown-body h4 {
  font-size: 16px;
}

.markdown-body h5 {
  font-size: 14px;
}

.markdown-body h5,
.markdown-body h6 {
  font-weight: 600;
}

.markdown-body h6 {
  font-size: 12px;
}

.markdown-body p {
  margin-top: 0;
  margin-bottom: 10px;
}

.markdown-body blockquote {
  margin: 0;
}

.markdown-body ol,
.markdown-body ul {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.markdown-body ol ol,
.markdown-body ul ol {
  list-style-type: lower-roman;
}

.markdown-body ol ol ol,
.markdown-body ol ul ol,
.markdown-body ul ol ol,
.markdown-body ul ul ol {
  list-style-type: lower-alpha;
}

.markdown-body dd {
  margin-left: 0;
}

.markdown-body code,
.markdown-body pre {
  font-family: SFMono-Regular,Consolas,Liberation Mono,Menlo,monospace;
  font-size: 12px;
}

.markdown-body pre {
  margin-top: 0;
  margin-bottom: 0;
}

.markdown-body input::-webkit-inner-spin-button,
.markdown-body input::-webkit-outer-spin-button {
  margin: 0;
  -webkit-appearance: none;
  appearance: none;
}

.markdown-body :checked+.radio-label {
  position: relative;
  z-index: 1;
  border-color: #0366d6;
}

.markdown-body .border {
  border: 1px solid #e1e4e8!important;
}

.markdown-body .border-0 {
  border: 0!important;
}

.markdown-body .border-bottom {
  border-bottom: 1px solid #e1e4e8!important;
}

.markdown-body .rounded-1 {
  border-radius: 3px!important;
}

.markdown-body .bg-white {
  background-color: #fff!important;
}

.markdown-body .bg-gray-light {
  background-color: #fafbfc!important;
}

.markdown-body .text-gray-light {
  color: #6a737d!important;
}

.markdown-body .mb-0 {
  margin-bottom: 0!important;
}

.markdown-body .my-2 {
  margin-top: 8px!important;
  margin-bottom: 8px!important;
}

.markdown-body .pl-0 {
  padding-left: 0!important;
}

.markdown-body .py-0 {
  padding-top: 0!important;
  padding-bottom: 0!important;
}

.markdown-body .pl-1 {
  padding-left: 4px!important;
}

.markdown-body .pl-2 {
  padding-left: 8px!important;
}

.markdown-body .py-2 {
  padding-top: 8px!important;
  padding-bottom: 8px!important;
}

.markdown-body .pl-3,
.markdown-body .px-3 {
  padding-left: 16px!important;
}

.markdown-body .px-3 {
  padding-right: 16px!important;
}

.markdown-body .pl-4 {
  padding-left: 24px!important;
}

.markdown-body .pl-5 {
  padding-left: 32px!important;
}

.markdown-body .pl-6 {
  padding-left: 40px!important;
}

.markdown-body .f6 {
  font-size: 12px!important;
}

.markdown-body .lh-condensed {
  line-height: 1.25!important;
}

.markdown-body .text-bold {
  font-weight: 600!important;
}

.markdown-body .pl-c {
  color: #6a737d;
}

.markdown-body .pl-c1,
.markdown-body .pl-s .pl-v {
  color: #005cc5;
}

.markdown-body .pl-e,
.markdown-body .pl-en {
  color: #6f42c1;
}

.markdown-body .pl-s .pl-s1,
.markdown-body .pl-smi {
  color: #24292e;
}

.markdown-body .pl-ent {
  color: #22863a;
}

.markdown-body .pl-k {
  color: #d73a49;
}

.markdown-body .pl-pds,
.markdown-body .pl-s,
.markdown-body .pl-s .pl-pse .pl-s1,
.markdown-body .pl-sr,
.markdown-body .pl-sr .pl-cce,
.markdown-body .pl-sr .pl-sra,
.markdown-body .pl-sr .pl-sre {
  color: #032f62;
}

.markdown-body .pl-smw,
.markdown-body .pl-v {
  color: #e36209;
}

.markdown-body .pl-bu {
  color: #b31d28;
}

.markdown-body .pl-ii {
  color: #fafbfc;
  background-color: #b31d28;
}

.markdown-body .pl-c2 {
  color: #fafbfc;
  background-color: #d73a49;
}

.markdown-body .pl-c2:before {
  content: "^M";
}

.markdown-body .pl-sr .pl-cce {
  font-weight: 700;
  color: #22863a;
}

.markdown-body .pl-ml {
  color: #735c0f;
}

.markdown-body .pl-mh,
.markdown-body .pl-mh .pl-en,
.markdown-body .pl-ms {
  font-weight: 700;
  color: #005cc5;
}

.markdown-body .pl-mi {
  font-style: italic;
  color: #24292e;
}

.markdown-body .pl-mb {
  font-weight: 700;
  color: #24292e;
}

.markdown-body .pl-md {
  color: #b31d28;
  background-color: #ffeef0;
}

.markdown-body .pl-mi1 {
  color: #22863a;
  background-color: #f0fff4;
}

.markdown-body .pl-mc {
  color: #e36209;
  background-color: #ffebda;
}

.markdown-body .pl-mi2 {
  color: #f6f8fa;
  background-color: #005cc5;
}

.markdown-body .pl-mdr {
  font-weight: 700;
  color: #6f42c1;
}

.markdown-body .pl-ba {
  color: #586069;
}

.markdown-body .pl-sg {
  color: #959da5;
}

.markdown-body .pl-corl {
  text-decoration: underline;
  color: #032f62;
}

.markdown-body .mb-0 {
  margin-bottom: 0!important;
}

.markdown-body .my-2 {
  margin-bottom: 8px!important;
}

.markdown-body .my-2 {
  margin-top: 8px!important;
}

.markdown-body .pl-0 {
  padding-left: 0!important;
}

.markdown-body .py-0 {
  padding-top: 0!important;
  padding-bottom: 0!important;
}

.markdown-body .pl-1 {
  padding-left: 4px!important;
}

.markdown-body .pl-2 {
  padding-left: 8px!important;
}

.markdown-body .py-2 {
  padding-top: 8px!important;
  padding-bottom: 8px!important;
}

.markdown-body .pl-3 {
  padding-left: 16px!important;
}

.markdown-body .pl-4 {
  padding-left: 24px!important;
}

.markdown-body .pl-5 {
  padding-left: 32px!important;
}

.markdown-body .pl-6 {
  padding-left: 40px!important;
}

.markdown-body .pl-7 {
  padding-left: 48px!important;
}

.markdown-body .pl-8 {
  padding-left: 64px!important;
}

.markdown-body .pl-9 {
  padding-left: 80px!important;
}

.markdown-body .pl-10 {
  padding-left: 96px!important;
}

.markdown-body .pl-11 {
  padding-left: 112px!important;
}

.markdown-body .pl-12 {
  padding-left: 128px!important;
}

.markdown-body hr {
  border-bottom-color: #eee;
}

.markdown-body kbd {
  display: inline-block;
  padding: 3px 5px;
  font: 11px SFMono-Regular,Consolas,Liberation Mono,Menlo,monospace;
  line-height: 10px;
  color: #444d56;
  vertical-align: middle;
  background-color: #fafbfc;
  border: 1px solid #d1d5da;
  border-radius: 3px;
  box-shadow: inset 0 -1px 0 #d1d5da;
}

.markdown-body:after,
.markdown-body:before {
  display: table;
  content: "";
}

.markdown-body:after {
  clear: both;
}

.markdown-body>:first-child {
  margin-top: 0!important;
}

.markdown-body>:last-child {
  margin-bottom: 0!important;
}

.markdown-body a:not([href]) {
  color: inherit;
  text-decoration: none;
}

.markdown-body blockquote,
.markdown-body details,
.markdown-body dl,
.markdown-body ol,
.markdown-body p,
.markdown-body pre,
.markdown-body table,
.markdown-body ul {
  margin-top: 0;
  margin-bottom: 16px;
}

.markdown-body hr {
  height: .25em;
  padding: 0;
  margin: 24px 0;
  background-color: #e1e4e8;
  border: 0;
}

.markdown-body blockquote {
  padding: 0 1em;
  color: #6a737d;
  border-left: .25em solid #dfe2e5;
}

.markdown-body blockquote>:first-child {
  margin-top: 0;
}

.markdown-body blockquote>:last-child {
  margin-bottom: 0;
}

.markdown-body h1,
.markdown-body h2,
.markdown-body h3,
.markdown-body h4,
.markdown-body h5,
.markdown-body h6 {
  margin-top: 24px;
  margin-bottom: 16px;
  font-weight: 600;
  line-height: 1.25;
}

.markdown-body h1 {
  font-size: 2em;
}

.markdown-body h1,
.markdown-body h2 {
  padding-bottom: .3em;
  border-bottom: 1px solid #eaecef;
}

.markdown-body h2 {
  font-size: 1.5em;
}

.markdown-body h3 {
  font-size: 1.25em;
}

.markdown-body h4 {
  font-size: 1em;
}

.markdown-body h5 {
  font-size: .875em;
}

.markdown-body h6 {
  font-size: .85em;
  color: #6a737d;
}

.markdown-body ol,
.markdown-body ul {
  padding-left: 2em;
}

.markdown-body ol ol,
.markdown-body ol ul,
.markdown-body ul ol,
.markdown-body ul ul {
  margin-top: 0;
  margin-bottom: 0;
}

.markdown-body li {
  word-wrap: break-all;
}

.markdown-body li>p {
  margin-top: 16px;
}

.markdown-body li+li {
  margin-top: .25em;
}

.markdown-body dl {
  padding: 0;
}

.markdown-body dl dt {
  padding: 0;
  margin-top: 16px;
  font-size: 1em;
  font-style: italic;
  font-weight: 600;
}

.markdown-body dl dd {
  padding: 0 16px;
  margin-bottom: 16px;
}

.markdown-body table {
  display: block;
  width: 100%;
  overflow: auto;
}

.markdown-body table th {
  font-weight: 600;
}

.markdown-body table td,
.markdown-body table th {
  padding: 6px 13px;
  border: 1px solid #dfe2e5;
}

.markdown-body table tr {
  background-color: #fff;
  border-top: 1px solid #c6cbd1;
}

.markdown-body table tr:nth-child(2n) {
  background-color: #f6f8fa;
}

.markdown-body img {
  max-width: 100%;
  box-sizing: initial;
  background-color: #fff;
}

.markdown-body img[align=right] {
  padding-left: 20px;
}

.markdown-body img[align=left] {
  padding-right: 20px;
}

.markdown-body code {
  padding: .2em .4em;
  margin: 0;
  font-size: 85%;
  background-color: rgba(27,31,35,.05);
  border-radius: 3px;
}

.markdown-body pre {
  word-wrap: normal;
}

.markdown-body pre>code {
  padding: 0;
  margin: 0;
  font-size: 100%;
  word-break: normal;
  white-space: pre;
  background: transparent;
  border: 0;
}

.markdown-body .highlight {
  margin-bottom: 16px;
}

.markdown-body .highlight pre {
  margin-bottom: 0;
  word-break: normal;
}

.markdown-body .highlight pre,
.markdown-body pre {
  padding: 16px;
  overflow: auto;
  font-size: 85%;
  line-height: 1.45;
  background-color: #f6f8fa;
  border-radius: 3px;
}

.markdown-body pre code {
  display: inline;
  max-width: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  line-height: inherit;
  word-wrap: normal;
  background-color: initial;
  border: 0;
}

.markdown-body .commit-tease-sha {
  display: inline-block;
  font-family: SFMono-Regular,Consolas,Liberation Mono,Menlo,monospace;
  font-size: 90%;
  color: #444d56;
}

.markdown-body .full-commit .btn-outline:not(:disabled):hover {
  color: #005cc5;
  border-color: #005cc5;
}

.markdown-body .blob-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
}

.markdown-body .blob-wrapper-embedded {
  max-height: 240px;
  overflow-y: auto;
}

.markdown-body .blob-num {
  width: 1%;
  min-width: 50px;
  padding-right: 10px;
  padding-left: 10px;
  font-family: SFMono-Regular,Consolas,Liberation Mono,Menlo,monospace;
  font-size: 12px;
  line-height: 20px;
  color: rgba(27,31,35,.3);
  text-align: right;
  white-space: nowrap;
  vertical-align: top;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.markdown-body .blob-num:hover {
  color: rgba(27,31,35,.6);
}

.markdown-body .blob-num:before {
  content: attr(data-line-number);
}

.markdown-body .blob-code {
  position: relative;
  padding-right: 10px;
  padding-left: 10px;
  line-height: 20px;
  vertical-align: top;
}

.markdown-body .blob-code-inner {
  overflow: visible;
  font-family: SFMono-Regular,Consolas,Liberation Mono,Menlo,monospace;
  font-size: 12px;
  color: #24292e;
  word-wrap: normal;
  white-space: pre;
}

.markdown-body .pl-token.active,
.markdown-body .pl-token:hover {
  cursor: pointer;
  background: #ffea7f;
}

.markdown-body .tab-size[data-tab-size="1"] {
  tab-size: 1;
}

.markdown-body .tab-size[data-tab-size="2"] {
  tab-size: 2;
}

.markdown-body .tab-size[data-tab-size="3"] {
  tab-size: 3;
}

.markdown-body .tab-size[data-tab-size="4"] {
  tab-size: 4;
}

.markdown-body .tab-size[data-tab-size="5"] {
  tab-size: 5;
}

.markdown-body .tab-size[data-tab-size="6"] {
  tab-size: 6;
}

.markdown-body .tab-size[data-tab-size="7"] {
  tab-size: 7;
}

.markdown-body .tab-size[data-tab-size="8"] {
  tab-size: 8;
}

.markdown-body .tab-size[data-tab-size="9"] {
  tab-size: 9;
}

.markdown-body .tab-size[data-tab-size="10"] {
  tab-size: 10;
}

.markdown-body .tab-size[data-tab-size="11"] {
  tab-size: 11;
}

.markdown-body .tab-size[data-tab-size="12"] {
  tab-size: 12;
}

.markdown-body .task-list-item {
  list-style-type: none;
}

.markdown-body .task-list-item+.task-list-item {
  margin-top: 3px;
}

.markdown-body .task-list-item input {
  margin: 0 .2em .25em -1.6em;
  vertical-align: middle;
}

.EditPost_editPost__3e5o0 {
  color: white;
}
.EditPost_editPost__3e5o0 .EditPost_title__lXAG8 {
  color: white;
  margin: 0;
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;
  border-bottom: 1px solid rgb(247, 247, 247);
}

.EditPost_editPost__3e5o0 .EditPost_formLabel__3WOzG {
  font-size: 1.3rem;
}

.ProjectChild_ProjectChild__2Kx3x {
  color: white;
}

.ProjectChild_ProjectChild__2Kx3x img {
  display: block;
  width: 208px;
  height: 117px;
  margin: 20px;
  border: 3px solid white;
  border-radius: 10px;
}

.ProjectChild_ProjectChild__2Kx3x .ProjectChild_content__1a47s {
  margin: 20px auto 40px;
  border-bottom: 1px solid #ccc;
}

.ProjectChild_ProjectChild__2Kx3x p {
  font-size: 1rem;
  padding: 5px 0;
}

.ProjectChild_ProjectChild__2Kx3x .ProjectChild_content__1a47s .ProjectChild_link__2xnhZ {
  margin: 0 5px;
}

.ProjectOverviewComponent_ProjectOverview__2tu3_ ul {
  margin: 10px auto;
  font-size: 1.2em;
  padding-left: 15px;
}

.ProjectOverviewComponent_ProjectOverview__2tu3_ .ProjectOverviewComponent_title__3AUFN {
  color: white;
  margin: 70px 0 30px 0;
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;
  border-bottom: 1px solid rgb(247, 247, 247);
}

.ProjectOverviewComponent_ProjectOverview__2tu3_ ul {
  color: #ccc;
  list-style-type: none;
  padding: 0;
}

@media (min-width: 320px) {
  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
}
@media (min-width: 480px) {
  /* smartphones, Android phones, landscape iPhone */
  .ProjectOverviewComponent_ProjectOverview__2tu3_ {
    font-size: 100%;
  }
  .ProjectOverviewComponent_ProjectOverview__2tu3_ .ProjectOverviewComponent_title__3AUFN {
    font-size: 160%;
  }
}
@media (min-width: 600px) {
  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
}
@media (min-width: 801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .ProjectOverviewComponent_ProjectOverview__2tu3_ {
    font-size: 110%;
  }
  .ProjectOverviewComponent_ProjectOverview__2tu3_ .ProjectOverviewComponent_title__3AUFN {
    font-size: 180%;
  }
}
@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}
@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
}

.RGCNComponent_rgcn__3ENDg {
  color: white;
  min-height: 650px;
}

.RGCNComponent_rgcn__3ENDg h1 {
  font-size: 1.7rem;
  margin: 50px 0 0 0;
  text-align: center;
  font-weight: 600;
}
.RGCNComponent_rgcn__3ENDg h4 {
  font-size: 1.3rem;
  text-align: center;
  color: white;
  padding: 0;
  margin-top: 5px;
}

.RGCNComponent_rgcn__3ENDg .RGCNComponent_github__2H1ip {
  text-align: center;
  margin: 5px 0 35px 0;
}

.RGCNComponent_rgcn__3ENDg .RGCNComponent_content__2Ya-D {
  padding: 0 auto;
}

.RGCNComponent_rgcn__3ENDg .RGCNComponent_content__2Ya-D p {
  margin-bottom: 40px;
}

.RGCNComponent_rgcn__3ENDg .RGCNComponent_content__2Ya-D ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.RGCNComponent_rgcn__3ENDg a:link {
  color: rgb(206, 203, 249);
}

/* visited link */
.RGCNComponent_rgcn__3ENDg a:visited {
  color: rgb(79, 158, 184);
}

/* mouse over link, selected link */
.RGCNComponent_rgcn__3ENDg a:hover,
.RGCNComponent_rgcn__3ENDg a:active {
  color: rgba(155, 138, 240, 0.879);
}

.GCNwithKD_gcnwithkd__1qr3_ {
  color: white;
  min-height: 650px;
}

.GCNwithKD_gcnwithkd__1qr3_ h1 {
  font-size: 1.7rem;
  margin: 50px 0 0 0;
  text-align: center;
  font-weight: 600;
}
.GCNwithKD_gcnwithkd__1qr3_ h4 {
  font-size: 1.3rem;
  text-align: center;
  color: white;
  padding: 0;
  margin-top: 5px;
}

.GCNwithKD_gcnwithkd__1qr3_ ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 80px;
}

.GCNwithKD_gcnwithkd__1qr3_ .GCNwithKD_github__3AGPk {
  text-align: center;
  margin: 5px 0 35px 0;
}

.GCNwithKD_gcnwithkd__1qr3_ .GCNwithKD_content__1EP9a {
  padding: 0 auto;
}

.GCNwithKD_gcnwithkd__1qr3_ .GCNwithKD_content__1EP9a p {
  margin-bottom: 40px;
}

.GCNwithKD_gcnwithkd__1qr3_ a:link {
  color: rgb(206, 203, 249);
}

/* visited link */
.GCNwithKD_gcnwithkd__1qr3_ a:visited {
  color: rgb(79, 158, 184);
}

/* mouse over link, selected link */
.GCNwithKD_gcnwithkd__1qr3_ a:hover,
.GCNwithKD_gcnwithkd__1qr3_ a:active {
  color: rgba(155, 138, 240, 0.879);
}

.SerialPortComponent_serialport__ovv7S {
  color: white;
  min-height: 650px;
}

.SerialPortComponent_serialport__ovv7S h1 {
  font-size: 1.7rem;
  margin-top: 50px;
  font-weight: 600;
  text-align: center;
}

.SerialPortComponent_serialport__ovv7S h4 {
  font-size: 1.3rem;
  text-align: center;
  color: white;
  padding: 0;
  margin-top: 5px;
}

.SerialPortComponent_serialport__ovv7S .SerialPortComponent_github__-7ufY {
  text-align: center;
  margin: 5px 0 35px 0;
}
.SerialPortComponent_serialport__ovv7S .SerialPortComponent_content__2R6lT {
  margin: 20px 0;
}

.SerialPortComponent_serialport__ovv7S a:link {
  color: rgb(206, 203, 249);
}

/* visited link */
.SerialPortComponent_serialport__ovv7S a:visited {
  color: rgb(79, 158, 184);
}

/* mouse over link, selected link */
.SerialPortComponent_serialport__ovv7S a:hover,
.SerialPortComponent_serialport__ovv7S a:active {
  color: rgba(155, 138, 240, 0.879);
}

.SerialPortComponent_serialport__ovv7S .SerialPortComponent_image__hRE5Z {
  display: block;
  margin: 20px auto;
  border-radius: 10px;
  width: 70%;
}

/* Contact */
.ContactComponent_contactContainer__1xp7u {
  color: white;
  margin-bottom: 2.5rem;
}

.ContactComponent_contactContainer__1xp7u h3 {
  color: white;
  margin: 30px 0;
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;
  border-bottom: 1px solid rgb(247, 247, 247);
}

