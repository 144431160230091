.newPost {
  color: white;
}
.newPost .title {
  color: white;
  margin: 0;
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;
  border-bottom: 1px solid rgb(247, 247, 247);
}

.newPost .formLabel {
  font-size: 1.3rem;
}
