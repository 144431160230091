.school {
  color: rgb(79, 25, 65);
  font-size: 1.2em;
}

.school .SchoolName {
  font-weight: 600;
  color: rgb(72, 31, 63);
}

.school ul {
  padding-left: 20px;
}

.school ul li {
  padding-top: 10px;
}
