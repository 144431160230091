.aboutContainer {
  margin-bottom: 2.5rem;
  color: #343333;
}
.aboutContainer hr {
  border-top: 1px solid rgb(155, 148, 148);
}

.aboutContainer h1 {
  color: white;
  margin: 70px 0 30px 0;
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;
  border-bottom: 1px solid rgb(247, 247, 247);
}
