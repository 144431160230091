.card {
  text-align: left;
  padding: 10px;
  border-radius: 20px;
  margin-bottom: 10px;
}
.cardTitle {
  color: rgb(92, 46, 82);
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  font-size: 2rem;
  padding: 0;
  margin: 0;
}

.cardSubTitle {
  color: rgb(138, 18, 110);
  display: flex;
  justify-content: space-between;
  font-style: italic;
  font-weight: 600;
  font-size: 1.2em;
  margin-top: 15px;
}

.cardSubTitle .period {
  font-size: 1.1rem;
  font-style: initial;
  padding: 0;
  margin-left: 5px;
}
.cardText {
  margin-top: 15px;
  font-size: 1.1rem;
  color: rgb(65, 1, 49);
  padding: 15px;
}

.cardText li {
  padding: 5px;
}
