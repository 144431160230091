.ProjectOverview ul {
  margin: 10px auto;
  font-size: 1.2em;
  padding-left: 15px;
}

.ProjectOverview .title {
  color: white;
  margin: 70px 0 30px 0;
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;
  border-bottom: 1px solid rgb(247, 247, 247);
}

.ProjectOverview ul {
  color: #ccc;
  list-style-type: none;
  padding: 0;
}

@media (min-width: 320px) {
  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
}
@media (min-width: 480px) {
  /* smartphones, Android phones, landscape iPhone */
  .ProjectOverview {
    font-size: 100%;
  }
  .ProjectOverview .title {
    font-size: 160%;
  }
}
@media (min-width: 600px) {
  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
}
@media (min-width: 801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .ProjectOverview {
    font-size: 110%;
  }
  .ProjectOverview .title {
    font-size: 180%;
  }
}
@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}
@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
}
