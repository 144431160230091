/* Header */
.Navbar {
  background-color: rgba(61, 63, 63, 0.3);
  padding: 20px;
}

.Navbar .NavbarBrand {
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: 2rem;
  font-style: italic;
  font-weight: 600;
}

.Navbar #NavLink {
  font-family: 'Montserrat', sans-serif;
  color: white;
  font-weight: 700;
  padding: 1.1rem;
}
.Navbar #NavLink:hover,
.Navbar #NavLink.active,
.Navbar #NavLink:active {
  font-family: 'Montserrat', sans-serif;
  background-color: rgba(226, 244, 244, 0.2);
  color: white;
  padding: 1.1rem;
}

.Navbar .dropdownItem:hover,
.Navbar .dropdownItem.active,
.Navbar .dropdownItem:active {
  background: #c951c9;
  color: #343333;
  width: 100%;
}
