/* Contact */
.contactContainer {
  color: white;
  margin-bottom: 2.5rem;
}

.contactContainer h3 {
  color: white;
  margin: 30px 0;
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;
  border-bottom: 1px solid rgb(247, 247, 247);
}
