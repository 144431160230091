/* Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Noto+Sans:wght@400;700&family=Roboto&display=swap');

/* General */
.content {
  min-height: 910px;
  height: 100%;
}

.bg-img {
  background: #1d2b64; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to top,
    #f8cdda,
    #1d2b64
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top,
    #f8cdda,
    #1d2b64
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

a {
  color: #ddeff7;
}

/* Aboutpage */
.cardimg {
  margin: 20px auto 0 auto;
  max-width: 50%;
  clip-path: circle(50% at 50% 50%);
}

.cardimgWrap {
  filter: drop-shadow(0 0 0.5rem rgb(44, 46, 113));
}
.card {
  color: #343333;
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  font-family: 'Noto Sans', sans-serif;
  padding: 5%;
}

.cardtext {
  font-size: 1.1rem;
  font-weight: 100;
  text-align: center;
}
