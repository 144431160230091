.serialport {
  color: white;
  min-height: 650px;
}

.serialport h1 {
  font-size: 1.7rem;
  margin-top: 50px;
  font-weight: 600;
  text-align: center;
}

.serialport h4 {
  font-size: 1.3rem;
  text-align: center;
  color: white;
  padding: 0;
  margin-top: 5px;
}

.serialport .github {
  text-align: center;
  margin: 5px 0 35px 0;
}
.serialport .content {
  margin: 20px 0;
}

.serialport a:link {
  color: rgb(206, 203, 249);
}

/* visited link */
.serialport a:visited {
  color: rgb(79, 158, 184);
}

/* mouse over link, selected link */
.serialport a:hover,
.serialport a:active {
  color: rgba(155, 138, 240, 0.879);
}

.serialport .image {
  display: block;
  margin: 20px auto;
  border-radius: 10px;
  width: 70%;
}
