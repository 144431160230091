.card {
  text-align: left;
  padding: 10px;
  margin: 10px 0;
  border-radius: 20px;
}
.cardTitle {
  color: rgb(254, 243, 253);
  font-weight: 600;
  font-size: 2rem;
  padding: 0;
  margin: 0;
  display: block;
}

.cardTitle .location {
  font-size: 1.2rem;
}

.cardSubTitle {
  display: inline;
  font-style: italic;
  font-weight: 600;
  font-size: 1.4rem;
  padding-left: 15px;
  color: rgb(247, 109, 109);
}

.cardSubTitle .period {
  float: right;
  font-size: 1.1rem;
  font-style: initial;
  padding: 0;
  margin-left: 5px;
  color: rgb(254, 243, 253);
}
.cardText {
  margin: 30px 0;
  padding: 15px;
  font-size: 1.1rem;
  color: rgb(249, 223, 246);
}
.cardText li {
  padding: 5px;
}
