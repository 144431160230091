.Blog {
  color: black;
}

.Blog .title {
  color: white;
  margin: 0;
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;
  border-bottom: 1px solid rgb(247, 247, 247);
}
.Blog .posts {
  padding: 10px;
  margin: 40px auto;
  background-color: whitesmoke;
  border-radius: 15px;
}
